import React, { FC } from 'react';
import styled from 'styled-components';
import { NavigationState, NavigationStateProps } from '../state/Navigation';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import {
  BoxedContent,
  BREAKPOINT_LG,
  ContainedImage,
  DIMEN_HEADER_HEIGHT,
  DIMEN_HEADER_HEIGHT_MOBILE,
  Icon,
} from '../styles/GlobalStyle';
import { Theme } from '../styles/Theme';

const HeaderWrapper = styled.header`
  width: 100vw;
  background-color: #fff;

  position: sticky;
  top: 0;
  z-index: 10;

  pointer-events: all;
`;

const Header = styled.div`
  height: ${DIMEN_HEADER_HEIGHT_MOBILE};
  ${BoxedContent};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  padding: .5rem 1rem;

  ${BREAKPOINT_LG} {
    height: ${DIMEN_HEADER_HEIGHT};
  }
`;

const ImageButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;

  transition: color 150ms ease-out;
  color: ${Theme.colors.green};

  &:hover {
    color: ${Theme.colors.pink};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;

    ${BREAKPOINT_LG} {
      width: 3rem;
      height: 3rem;
    }
  }
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  cursor: pointer;

  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  margin-right: auto;
`;

const Image = styled(ContainedImage)`
  width: auto;
  min-width: 0;
  max-width: 100%;
`;

export const PageHeader: FC = (props) => {
  const [navigationState, setNavigationState] = useRecoilState<NavigationStateProps>(NavigationState);

  return (
    <HeaderWrapper>
      <Header>
        <LinkWrapper to={'/'}>
          <Image
            src={'/images/boomer-logo.gif'}
            alt='Agentur-Boomer Logo'
          />
        </LinkWrapper>
        {
          /*
          <ImageButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setNavigationState({ isOpen: true });
            }}>
            <svg width='47' height='42' viewBox='0 0 47 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0)'>
                <path
                  d='M6.89446 8.2668C9.99199 7.66555 13.1395 7.15197 16.262 6.81377C19.3182 6.29508 22.4252 6.14372 25.5171 6.36285C28.4897 6.76368 31.3624 7.90354 34.3351 8.37953C37.4951 8.88058 40.6925 9.15617 43.8775 9.41922C44.7036 9.41267 45.494 9.08061 46.0782 8.49475C46.6624 7.90889 46.9934 7.1162 47 6.28769C46.984 5.46218 46.6499 4.67495 46.0677 4.09112C45.4856 3.50728 44.7006 3.1722 43.8775 3.15617C40.7552 2.96507 37.645 2.60967 34.5599 2.09144C31.3375 1.49019 28.2524 0.287679 24.9675 0.0496831C21.8511 -0.0439827 18.7326 0.14469 15.65 0.613373C12.1653 1.01421 8.68053 1.5528 5.23328 2.22921C4.83799 2.3386 4.46806 2.52503 4.1446 2.7778C3.82114 3.03057 3.55049 3.34476 3.34811 3.70243C3.14572 4.0601 3.01555 4.45422 2.96505 4.86234C2.91455 5.27046 2.9447 5.68457 3.05378 6.081C3.16285 6.47744 3.34872 6.84844 3.60076 7.17283C3.8528 7.49723 4.16609 7.76864 4.52273 7.97161C4.87937 8.17459 5.27238 8.30515 5.67932 8.35579C6.08627 8.40644 6.49917 8.37619 6.89446 8.2668Z'
                  fill='currentColor' />
                <path
                  d='M8.84297 23.6865C19.1496 22.9956 29.4869 22.8868 39.8058 23.3608C43.8151 23.5487 43.8151 17.2857 39.8058 17.0978C29.4869 16.6238 19.1496 16.7325 8.84297 17.4235C8.01983 17.4395 7.23487 17.7746 6.65271 18.3584C6.07056 18.9423 5.73645 19.7295 5.72046 20.555C5.72699 21.3835 6.05808 22.1762 6.64225 22.7621C7.22643 23.348 8.01685 23.68 8.84297 23.6865Z'
                  fill='currentColor' />
                <path
                  d='M3.93436 41.9372C11.1161 40.6846 18.4353 38.9685 25.7295 38.9184C29.259 39.1166 32.7753 38.3411 35.8964 36.6763C37.6824 35.6115 39.5559 33.0938 41.4419 35.2483C42.0288 35.8304 42.821 36.1569 43.6464 36.1569C44.4719 36.1569 45.264 35.8304 45.8509 35.2483C46.4225 34.6551 46.742 33.8624 46.742 33.0374C46.742 32.2125 46.4225 31.4198 45.8509 30.8266C44.6822 29.51 43.1207 28.6073 41.3989 28.2529C39.6772 27.8986 37.8874 28.1115 36.2961 28.86C34.7598 29.5865 33.5857 30.8641 32.0619 31.6282C30.4465 32.3414 28.6927 32.6838 26.9285 32.6303C18.6227 32.7681 10.4667 34.4967 2.27319 35.8621C1.48012 36.0928 0.80864 36.6258 0.402465 37.3469C-0.00371041 38.0679 -0.112302 38.9198 0.099924 39.7201C0.335685 40.5097 0.865537 41.1779 1.57952 41.5861C2.29351 41.9942 3.1368 42.111 3.93436 41.9122V41.9372Z'
                  fill='currentColor' />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect width='47' height='42' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </ImageButton>
           */
        }
      </Header>
    </HeaderWrapper>
  );
};