import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';

const LOCAL_STORAGE_KEY = 'de.agentur-boomer.website.store:COOKIES_ACCEPTED';

interface CookieConsentStateProps {
  accepted: boolean | null;
  showBanner: boolean;
}

const CookieConsentState = atom<CookieConsentStateProps>({
  key: 'cookieState',
  default: {
    accepted: null,
    showBanner: false,
  },
});

export const useCookiesAllowed = () => {
  return useRecoilValue(CookieConsentState).accepted === true;      // defined and true
}

export const useCookieConfirmationPending = () => {
  return useRecoilValue(CookieConsentState).accepted === null;      // not defined
}

export const useCookiesDeclined = () => {
  return useRecoilValue(CookieConsentState).accepted === false;     // defined and false
}

export const useCookieBannerState = () => {
  const [state, setState] = useRecoilState(CookieConsentState);

  // init cookie consent state
  useEffect(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY) || null;
    if (storedValue === 'yes') {
      setState((state) => ({ ...state, accepted: true }));
    } else if (storedValue === 'no') {
      setState((state) => ({ ...state, accepted: false }));
    } else {
      setState((state) => ({ ...state, showBanner: true }));
    }
  }, []);

  const setShowBanner = (show: boolean, accepted?: boolean) => {
    if (accepted !== undefined) {
      localStorage.setItem(LOCAL_STORAGE_KEY, accepted ? 'yes' : 'no');
    }
    setState((state) => ({
      accepted: accepted !== undefined ? accepted : state.accepted,
      showBanner: show,
    }));
  }

  return {
    showBanner: state.showBanner,
    setShowBanner,
  };
}