import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RgbColor } from './Theme';
import {
  BoxedContent,
  BREAKPOINT_LG, DIMEN_HEADER_HEIGHT,
  DIMEN_HEADER_HEIGHT_MOBILE,
  getDesktopProp,
  getMobileProp,
  ResponsiveType,
} from './GlobalStyle';

export interface PageSectionProps {
  backgroundColor?: RgbColor;
  backgroundOpacity?: number;
  minHeight?: ResponsiveType<string>;
  marginBottom?: ResponsiveType<string>;
  fullscreen?: ResponsiveType<boolean>;
}

const StyledSection = styled.section<PageSectionProps>`
  background: ${({
                   backgroundColor,
                   backgroundOpacity,
                 }) => `rgba(${backgroundColor ? backgroundColor.r : 255},${backgroundColor ? backgroundColor.g : 255},${backgroundColor ? backgroundColor.b : 255}, ${backgroundOpacity !== undefined ? backgroundOpacity : 1})`};
  min-height: ${({ minHeight, fullscreen }) => minHeight ? getMobileProp(minHeight) : getMobileProp(fullscreen) ? `calc(100vh - ${DIMEN_HEADER_HEIGHT_MOBILE})` : '15.75rem'};
  ${({ marginBottom }) => marginBottom && css`margin-bottom: ${ getMobileProp(marginBottom) }`};
  position: relative;
  
  display: flex;
  
  ${BREAKPOINT_LG} {
    min-height: ${({ minHeight, fullscreen = true }) => minHeight ? getDesktopProp(minHeight) : getDesktopProp(fullscreen) ? `calc(100vh - ${DIMEN_HEADER_HEIGHT})` : '15.75rem'};
    ${({ marginBottom }) => marginBottom && css`margin-bottom: ${ getDesktopProp(marginBottom) }`};
  }
`;

export const StyledSectionBoxedWrapper = styled.div`
  ${BoxedContent};
  position: relative;
  
  flex: 1;
  align-self: stretch;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageSection: FC<PageSectionProps> = (props) => {
  return (
    <StyledSection {...props}>
      { props.children }
    </StyledSection>
  );
};