import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageSection, StyledSectionBoxedWrapper } from '../styles/PageSection';
import { Theme } from '../styles/Theme';
import { Headline } from '../components/Headline';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ContentWrapper = styled(StyledSectionBoxedWrapper)`
  padding: 4.5rem 1.5rem;

  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
`;

export const NewsletterOptOut: FC = () => {
  const { token } = useParams<{ token: string }>();

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    axios.post('/proxy/newsletter-subscriptions/opt-out', { token }).then((response) => {
      setSuccess(true);
    }).catch((error) => {
      console.log((error));
      setError(true);
    });
  }, [token]);

  return (
    <PageSection backgroundColor={Theme.colors.rgb.purple_light}>
      <ContentWrapper>
        {
          success && <Headline level={3} textColor={Theme.colors.rgb.purple} textAlign={'left'}>
            Dein Abonnement wurde beendet, du erhältst ab sofort keine E-Mails mehr von uns.
          </Headline>
        }
        {
          error && <Headline level={3} textColor={Theme.colors.rgb.purple} textAlign={'left'}>
            Leider können deine Anfrage zurzeit nicht verarbeiten.<br />
            Bitte probiere es später erneut oder schick unserem Support eine E-Mail an info@agentur-boomer.de
          </Headline>
        }
      </ContentWrapper>
    </PageSection>
  );
};