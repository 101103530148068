import React, { FC, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Button } from '../Button';
import axios from 'axios';
import { FlexboxVertical } from '../../styles/Layouts';
import { Theme } from '../../styles/Theme';
import { TextBlock } from '../TextBlock';
import { CheckBox } from './CheckBox';
import { Link } from 'react-router-dom';


const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;

  ${Button} {
    margin-top: 1.5rem;
    margin-right: auto;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${Theme.colors.purple};


  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const SuccessBox = styled(FlexboxVertical)`
  padding: 0;
  margin: 0 4rem 0 0;
  min-height: 22rem;

  border: 2px solid ${Theme.colors.green};

  position: relative;

  p {
    padding: 2rem 1.5rem;
    flex: 1;
    background-color: rgba(${['r', 'g', 'b'].map((key) => (Theme.colors.rgb.green as any)[key]).join(', ')}, .18);
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: 50px;
      left: 100%;
      background-color: #fff;
      width: 2px;
      height: 47px;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50px;
      left: 100%;
      background-color: rgba(${['r', 'g', 'b'].map((key) => (Theme.colors.rgb.green as any)[key]).join(', ')}, .18);
      width: 2px;
      height: 47px;
      z-index: 2;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 3rem;
    left: 100%;
    width: 4rem;
    height: 3rem;

    background-color: rgba(${['r', 'g', 'b'].map((key) => (Theme.colors.rgb.green as any)[key]).join(', ')}, .18);
    border: 2px solid ${Theme.colors.green};
    border-width: 2px 2px 0 0;
    transform: matrix(1, 0, -1.3, 1, 0, 0) translate(-2rem, 0);
    z-index: 0;
  }
`;

export interface NewsletterFormStateProps {
  firstname: string | null;
  lastname: string | null;
  company: string | null;
  mail: string | null;
  newsletterSubscription: boolean | null;
  jobListSubscription: boolean | null;
}

export interface NewsletterFormErrorStateProps {
  mail: boolean;
  newsletterSubscription: boolean;
  jobListSubscription: boolean;
}

const initialNewsletterFormState = {
  firstname: null,
  lastname: null,
  company: null,
  mail: null,
  newsletterSubscription: null,
  jobListSubscription: null,
};

const initialNewsletterFormErrorState = {
  mail: false,
  newsletterSubscription: false,
  jobListSubscription: false,
};

export const NewsletterForm: FC = (props) => {
  const [formState, setFormState] = useState<NewsletterFormStateProps>(initialNewsletterFormState);
  const [formErrorState, setFormErrorState] = useState<NewsletterFormErrorStateProps>(initialNewsletterFormErrorState);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);

  const hasOneNewsletterOptIn = (): boolean => {
    const hasNewsletterSubscriptionOptIn = (formState.newsletterSubscription !== null && formState.newsletterSubscription);
    const hasJobListOptIn = (formState.jobListSubscription !== null && formState.jobListSubscription);
    return hasNewsletterSubscriptionOptIn || hasJobListOptIn;
  };

  const submit = () => {
    setApiError(false);
    if (loading) {
      return;
    }

    let localError: NewsletterFormErrorStateProps = {
      mail: !formState.mail,
      newsletterSubscription: !hasOneNewsletterOptIn(),
      jobListSubscription: !hasOneNewsletterOptIn(),
    };

    const hasErrors = Object.keys(localError).some((key) => Boolean((localError as any)[key]));

    if (!hasErrors) {
      setFormErrorState(localError);
      const url = '/proxy/newsletter-subscriptions';
      setLoading(true);
      axios.post(url, {
        firstname: formState.firstname || null,
        lastname: formState.lastname || null,
        company: formState.company || null,
        email: formState.mail,
        newsletterSubscription: formState.newsletterSubscription || false,
        jobListSubscription: formState.jobListSubscription || false,
      }).then((response) => {
        setLoading(false);
        setSuccess(true);
      }).catch((error) => {
        setLoading(false);
        setFormErrorState({
          ...localError,
        });
        setApiError(true);
      });
    } else {
      setFormErrorState(localError);
    }
  };

  if (success) {
    return (
      <SuccessBox>
        <TextBlock>Super! Du bist jetzt dabei und bekommst alle Updates!</TextBlock>
      </SuccessBox>
    );
  }

  return (
    <FlexboxVertical>
      {
        apiError &&
        <TextBlock style={{ marginBottom: '1rem' }} textColor={Theme.colors.pink}>Bitte versuchen Sie es später
          erneut.</TextBlock>
      }
      <StyledForm id={'agentur-boomer-newsletter-form'} onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        submit();
      }}>
        <Input
          id={'firstname'}
          label={'Vorname'}
          error={false}
          disabled={loading}
          value={formState.firstname}
          onValueChanged={(value) => {
            setFormState({ ...formState, firstname: value });
          }} />
        <Input
          id={'lastname'}
          label={'Nachname'}
          error={false}
          disabled={loading}
          value={formState.lastname}
          onValueChanged={(value) => {
            setFormState({ ...formState, lastname: value });
          }} />
        <Input
          id={'company'}
          label={'Firma'}
          disabled={loading}
          error={false}
          value={formState.company}
          onValueChanged={(value) => {
            setFormState({ ...formState, company: value });
          }} />
        <Input
          id={'email'}
          label={'E-Mail *'}
          disabled={loading}
          error={formErrorState.mail}
          value={formState.mail}
          onValueChanged={(value) => {
            setFormState({ ...formState, mail: value });
          }} />
        <CheckBox
          id={'newsletter-subscription'}
          label={'Ich möchte zum Start der Plattform informiert werden'}
          disabled={loading}
          error={formErrorState.newsletterSubscription}
          checked={formState.newsletterSubscription}
          onValueChanged={(value) => {
            setFormState({ ...formState, newsletterSubscription: value });
          }} />
        <CheckBox
          id={'job-list-subscription'}
          label={'Ich möchte den Newsletter abonnieren'}
          disabled={loading}
          error={formErrorState.jobListSubscription}
          checked={formState.jobListSubscription}
          onValueChanged={(value) => {
            setFormState({ ...formState, jobListSubscription: value });
          }} />
        <p>Hier geht es zu den <StyledLink to={'/disclaimer'}>Nutzungsbedingungen</StyledLink>.</p>
        <Button
          disabled={loading}
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();
            submit();
          }}>Absenden</Button>
      </StyledForm>
    </FlexboxVertical>
  );
};