import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { Theme } from '../../styles/Theme';
import { BREAKPOINT_LG } from '../../styles/GlobalStyle';
import { disabledStyle, InputWrapper } from './FormStyles';

export interface InputProps {
  id: string;
  label: string;
  disabled?: boolean;
  checked: boolean | null;
  onValueChanged: (checked: boolean) => void;
  error: boolean;
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const StyledLabel = styled.label<{ error?: boolean }>`
  margin-bottom: 1rem;
  cursor: pointer;
  // user-select: none;
  color: ${({ error = false }) => !error ? 'none' : Theme.colors.pink}
`;

const HiddenCheckbox = styled.input<{ checked: boolean }>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon: FC<{ checked: boolean }> = () => {
  return (<svg xmlns='http://www.w3.org/2000/svg' width='27.063' height='27.063' viewBox='0 0 27.063 27.063'>
    <path style={{ fill: '#ff9ecf' }}
          d='M1187.641,526.243a1.553,1.553,0,0,0-1-.584,3.96,3.96,0,0,0-4.259,2.1c-.374.738-.443,1.58-.8,2.328A5.76,5.76,0,0,1,1179.9,532c-1.083.871-2.872,2.51-4.864,4.406-1.228-1.649-4.148-5.669-4.92-6.736-.915-1.26-1.921-2.455-2.926-3.642a1.534,1.534,0,0,0-2.152,0,1.553,1.553,0,0,0,0,2.153c1.009,1.14,3.5,4.373,4.382,5.613.757,1.1,2.312,3.328,3.413,4.733-3.21,3.12-6.475,6.416-7.934,8a1.542,1.542,0,0,0,.316,2.132,1.555,1.555,0,0,0,2.128-.3l.008.009c1.291-1.421,4.457-4.638,7.533-7.641a46.548,46.548,0,0,0,3.656,3.3c1.337,1.061,4.133,3.732,5.553,4.686a1.524,1.524,0,1,0,1.506-2.649c-1.273-.859-3.944-3.422-5.135-4.381a35.751,35.751,0,0,1-3.4-3.059c1.774-1.691,3.342-3.126,4.258-3.848a9.394,9.394,0,0,0,3.238-3.9c.369-.943.337-2.47,1.7-2.2a1.523,1.523,0,0,0,1.695-1.316A1.552,1.552,0,0,0,1187.641,526.243Z'
          transform='matrix(0.985, -0.174, 0.174, 0.985, -1238.182, -311.319)' />
  </svg>);
};

const StyledIcon = styled(Icon)<{ checked: boolean }>`

`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background: ${Theme.colors.lightGreen};
  border: 1px solid ${Theme.colors.green};
  transition: all 150ms;
  margin-right: .5rem;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${Theme.colors.pink};
  }

  > svg {
    visibility: ${({ checked }) => checked ? 'visible' : 'hidden'}
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CheckBox: FC<InputProps> = (props) => {
  const { id, label, disabled, checked, onValueChanged, error } = props;

  return (<CheckBoxWrapper onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      onValueChanged(!checked);
    }}>
      <StyledLabel htmlFor={id} error={error}>
        <CheckboxContainer>
          <HiddenCheckbox id={id} checked={checked || false}
                          onChange={(event) => onValueChanged(event.target.checked)} />
          <StyledCheckbox checked={checked || false}>
            <StyledIcon checked={checked || false} />
          </StyledCheckbox>
        </CheckboxContainer>
        <span>{label}</span>
      </StyledLabel>
    </CheckBoxWrapper>
  );
};