import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { Theme } from './styles/Theme';
import { GlobalStyle } from './styles/GlobalStyle';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <GlobalStyle />
      <ThemeProvider theme={Theme}>
          <App />
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
