export interface RgbColor {
  r: number;
  g: number;
  b: number;
};

export const Theme = {
  colors: {
    pink: '#FF9ECF',
    lightGreen: '#E9FFE0',
    green: '#85FF56',
    purple: '#5800FF',
    gray: '#A2A1A1',
    rgb: {
      purple_light: {
        r: 244,
        g: 241,
        b: 251,
      },
      pink: {
        r: 255,
        g: 158,
        b: 207,
      },
      green: {
        r: 133,
        g: 255,
        b: 86,
      },
      purple: {
        r: 88,
        g: 0,
        b: 255,
      },
      white: {
        r: 255,
        g: 255,
        b: 255,
      },
    },
  },
  fonts: {
    neueHelvetica: 'Neue-Helvetica-Std-65-Medium',
    monumentExtendedBold: 'PPMonumentExtended-Bold',
    monumentExtendedRegular: 'PPMonumentExtended-Regular',
  },
  fontSizes: {
    headlines: {
      h1: '7.5rem', // 120px
      h2: '5.625rem', // 90px
      h3: '4.0625rem', // 65px
      h4: '3.125rem', // 50px
      h5: '1.5625rem', // 25px
      h6: '1.25rem', // 20px
    },
    headlinesResponsive: {
      h1: '1.6875rem', // 27px
      h2: '1.6875rem', // 27px
      h3: '1.5rem', // 24px
      h4: '1.6875rem', // 27px
      h5: '1.125rem', // 18px
      h6: '1.25rem', // 20px
    },
  },
};
