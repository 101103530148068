import { FC } from 'react';
import { Flexbox, FlexboxVertical } from '../styles/Layouts';
import styled from 'styled-components';
import { Theme } from '../styles/Theme';
import { TextBlock } from './TextBlock';
import { Button } from './Button';
import { useCookieBannerState } from '../state/CookieConsent';
import { BoxedContent, BREAKPOINT_LG, BREAKPOINT_SM } from '../styles/GlobalStyle';

const Wrapper = styled.div`
  ${BoxedContent};
`;

const InnerWrapper = styled(FlexboxVertical)`
  position: fixed;
  bottom: 0;
  z-index: 100;

  width: 100%;
  max-width: 850px;
  max-height: 100%;
  overflow: auto;

  background-color: ${Theme.colors.pink};

  padding: 2rem;

  ${BREAKPOINT_LG} {
    padding: 3rem 4rem;
  }

  ${TextBlock} {
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0 0 3rem;
  }
`;

const ButtonWrapper = styled(FlexboxVertical)`
  flex-direction: column-reverse;
  align-items: center;

  ${Button} {
    font-size: 1.125rem;
    text-align: center;
    justify-content: center;
    min-width: 12rem;
    margin: 0 0 2rem;

    &:first-child {
      margin: 0;
    }
  }

  ${BREAKPOINT_SM} {
    flex-direction: row;
    justify-content: flex-end;

    ${Button} {
      margin: 0 0 0 2rem;
    }
  }
`;

export const CookieConsent: FC = () => {
  const { showBanner, setShowBanner } = useCookieBannerState();

  const onAccept = () => {
    setShowBanner(false, true);
  };

  const onDecline = () => {
    setShowBanner(false, false);
  };

  return (
    <>
      {
        showBanner &&
        <Wrapper>
          <InnerWrapper>
            <TextBlock>
              Diese Webseite verwendet Cookies<br /><br />
              Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten
              zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer
              Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere
              Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen
              bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
            </TextBlock>
            {/*<TextBlock>*/}
            {/*  Diese Website hackt sich direkt in Gehirn und ermöglicht Boomers, ab sofort alle deine Sinne mitbenutzen*/}
            {/*  und dich quasi fernsteuern zu können. Ach so, und beste Kumpel Mark Zuckerberg hat angerufen, er würde dir*/}
            {/*  gerne lustige Ads schicken. Geht das klar?*/}
            {/*</TextBlock>*/}
            <ButtonWrapper>
              <Button onClick={onDecline} style={{ textTransform: 'lowercase' }}>nö, lass ma</Button>
              <Button onClick={onAccept} style={{ textTransform: 'lowercase' }}>geht klar</Button>
            </ButtonWrapper>
          </InnerWrapper>
        </Wrapper>
      }
    </>
  );
};