import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageSection, StyledSectionBoxedWrapper } from '../styles/PageSection';
import { FlexboxVertical } from '../styles/Layouts';
import { BREAKPOINT_LG } from '../styles/GlobalStyle';
import { Theme } from '../styles/Theme';
import { Headline } from '../components/Headline';
import { NewsletterForm } from '../components/form/NewsletterForm';
import { TextBlock } from '../components/TextBlock';

export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
`;

export const BackgroundImageBase = styled(CoverImage)`
  position: absolute;
  top: 0;
  left: 0;
`;

const BackgroundImage = styled(BackgroundImageBase)`
  z-index: 1;
  max-height: 120%;
`;

const ContentWrapper = styled(StyledSectionBoxedWrapper)`
  padding: 4.5rem 1.5rem;

  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
`;

const NewsletterFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(${['r', 'g', 'b'].map((key) => (Theme.colors.rgb.green as any)[key]).join(', ')}, 1);
  padding: 1.5rem;
`;
export const Home: FC = () => {

  return (
    <PageSection backgroundColor={Theme.colors.rgb.purple_light}>
      <BackgroundImage src={'/images/star-green.png'} alt={'Auf Arbeit Werbepause hören & wieder nix schaffen'} />
      <ContentWrapper>
        <Headline level={3} textColor={Theme.colors.rgb.purple} textAlign={'left'} marginBottom={'1rem'}>
          Bald geht’s los …
        </Headline>
        <Headline level={5} textColor={Theme.colors.rgb.pink} textAlign={'left'} marginBottom={'1.5rem'}>
          Für eine Agenturwelt mit weniger <br/>
          Stoff für Boomer-Memes!
        </Headline>
        <NewsletterFormWrapper>
          <Headline level={6} textColor={Theme.colors.rgb.purple} textAlign={'left'} marginBottom={'1rem'}>
            Melde dich zum Boomer-Jobs Newsletter an
          </Headline>
          <TextBlock marginBottom={'1rem'}>
            Bald startet die Agentur Boomer Jobbörse - für alle Menschen mit einem Job in der Werbung und im Marketing. Finde Jobs mit Benefits, die wirklich zählen oder präsentiere als Arbeitgeber dein Unternehmen/ deine Agentur mit allen Eigenschaften.
          </TextBlock>
          <br />
          <NewsletterForm />
        </NewsletterFormWrapper>
      </ContentWrapper>
    </PageSection>
  );
};
