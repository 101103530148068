import { PageSection, StyledSectionBoxedWrapper } from '../styles/PageSection';
import { PageHeader } from '../components/PageHeader';
import { Theme } from '../styles/Theme';
import { Headline } from '../components/Headline';
import { PageFooter } from '../components/PageFooter';
import { TextBlock } from '../components/TextBlock';
import styled from 'styled-components';
import React, { FC } from 'react';

const ImprintWrapper = styled(StyledSectionBoxedWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  padding: 2rem 1.5rem;

  h1 {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 1.5rem;
    padding-left: 0;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: .25rem;
  }

  p {
    margin-bottom: 2.5rem;
  }
`;

export  const Imprint: FC = () => {
  return (
    <>
      <PageSection backgroundColor={Theme.colors.rgb.white}>
        <ImprintWrapper>
          <Headline level={1} textColor={Theme.colors.rgb.pink} borderColor={Theme.colors.rgb.pink}>
            Impressum
          </Headline>
          <Headline level={3} textColor={Theme.colors.rgb.pink} borderColor={Theme.colors.rgb.pink}>
            Angaben gemäß § 5 TMG
          </Headline>
          <TextBlock>
            brandneo GmbH<br />
            Am Kai 10<br />
            44263 Dortmund<br />
            <br />
            <strong>Vertreten durch die Geschäftsführer:</strong><br />
            Sebastian Galla<br />
            Volker Fritz<br />
            <br />
            <strong>Sitz der Gesellschaft:</strong><br />
            Dortmund<br />
            <br />
            <strong>Registergericht:</strong><br />
            Amtsgericht Dortmund<br />
            <br />
            <strong>Registernummer:</strong><br />
            HRB 30577<br />
            <br />
            <strong>USt-ID-Nr.:</strong><br />
            DE321862875<br />
          </TextBlock>
          <Headline level={3} textColor={Theme.colors.rgb.pink} borderColor={Theme.colors.rgb.pink}>
            Kontakt
          </Headline>
          <TextBlock>
            E-Mail: <a href={'mailto://info@agentur-boomer.de'}>info@agentur-boomer.de</a><br />
            Telefon: <a href={'tel://+4940228519111'}>+49 40 228 519 111</a><br />
          </TextBlock>
          <Headline level={3} textColor={Theme.colors.rgb.pink} borderColor={Theme.colors.rgb.pink}>
            Streitschlichtung
          </Headline>
          <TextBlock>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr.
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
            <br />
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle
            teilzunehmen.
          </TextBlock>
          <Headline level={3} textColor={Theme.colors.rgb.pink} borderColor={Theme.colors.rgb.pink}>
            Haftung für Inhalte
          </Headline>
          <TextBlock>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
            eine rechtswidrige Tätigkeit hinweisen.
            <br />
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
            konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </TextBlock>
          <Headline level={3} textColor={Theme.colors.rgb.pink} borderColor={Theme.colors.rgb.pink}>
            Haftung für Links
          </Headline>
          <TextBlock>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb
            können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
            stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
            der
            Verlinkung nicht erkennbar.
            <br />
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend
            entfernen.
          </TextBlock>
          <Headline level={3} textColor={Theme.colors.rgb.pink} borderColor={Theme.colors.rgb.pink}>
            Urheberrecht
          </Headline>
          <TextBlock>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen
            des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads
            und
            Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            <br />
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </TextBlock>
        </ImprintWrapper>
      </PageSection>
    </>
  );
}
