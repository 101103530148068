import styled, { createGlobalStyle, css } from 'styled-components';
import { Theme } from './Theme';
import { FC } from 'react';
import ReactGa, { EventArgs } from 'react-ga';

// constants
export const DIMEN_HEADER_HEIGHT = '5.875rem';
export const DIMEN_HEADER_HEIGHT_MOBILE = '4rem';

export const DIMEN_BOXED_CONTENT_WIDTH = 1280;

export const DIMEN_BREAKPOINT_SM = 576;
export const DIMEN_BREAKPOINT_LG = 992;
export const BREAKPOINT = (breakpoint: number) => `@media (min-width: ${breakpoint}px)`;
export const BREAKPOINT_SM = BREAKPOINT(DIMEN_BREAKPOINT_SM);
export const BREAKPOINT_LG = BREAKPOINT(DIMEN_BREAKPOINT_LG);


export const fontFaces = css`
  @font-face {
    font-family: ${Theme.fonts.monumentExtendedRegular};
    src: url('/fonts/PPMonumentExtended-Regular.woff2') format('woff2'),
    url('/fonts/PPMonumentExtended-Regular.woff') format('woff'),
    url('/fonts/PPMonumentExtended-Regular.eot') format('eot');
  }

  @font-face {
    font-family: ${Theme.fonts.monumentExtendedBold};
    src: url('/fonts/PPMonumentExtended-Bold.woff2') format('woff2'),
    url('/fonts/PPMonumentExtended-Bold.woff') format('woff'),
    url('/fonts/PPMonumentExtended-Bold.eot') format('eot');
  }

  @font-face {
    font-family: ${Theme.fonts.neueHelvetica};
    src: url('/fonts/Neue-Helvetica-Std-65-Medium.woff2') format('woff2'),
    url('/fonts/Neue-Helvetica-Std-65-Medium.woff') format('woff');
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${fontFaces};

  html {
    font-size: 16px;
  }

  body {
    font-family: ${Theme.fonts.neueHelvetica}, sans-serif;
    width: 100vw;
    position: relative;
    -webkit-overflow-scrolling: touch;      /* important for swiping on ios */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
  }

  body.noscroll {
    overflow: hidden;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const BoxedContent = css`
  margin: 0 auto;
  width: 100%;
  max-width: ${DIMEN_BOXED_CONTENT_WIDTH}px;
`;

export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
`;

export const BackgroundImage = styled(CoverImage)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ContainedImage = styled(CoverImage)`
  object-fit: contain;
`;

export const CoverVideo = styled.video<{ visible?: boolean }>`
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
  
  display: ${({ visible = true }) => visible ? 'flex' : 'none'};
`;
export const BackgroundVideo = styled(CoverVideo)`
  position: absolute;
  top: 0;
  left: 0;
`;
export const BackgroundVideoPlayer: FC<{ visible?: boolean }> = ({ visible, children }) => {
  return (
    <BackgroundVideo visible={visible} autoPlay loop muted playsInline>
      { children }
    </BackgroundVideo>
  )
}

export const Icon = styled(ContainedImage)<{ size?: ResponsiveType<string> }>`
  width: ${({ size = '1.5rem' }) => getMobileProp(size)};
  height: ${({ size = '1.5rem' }) => getMobileProp(size)};
  
  ${BREAKPOINT_LG} {
    width: ${({ size = '1.5rem' }) => getDesktopProp(size)};
    height: ${({ size = '1.5rem' }) => getDesktopProp(size)};
  }
`;

export interface ResponsiveProp<T> {
  mobile: T;
  desktop: T;
}
export type ResponsiveType<T> = ResponsiveProp<T> | T;

export const getMobileProp = (prop: ResponsiveType<any>) => {
  if (typeof prop === 'object') {
    return prop.mobile;
  }
  return prop;
}

export const getDesktopProp = (prop: ResponsiveType<any>) => {
  if (typeof prop === 'object') {
    return prop.desktop;
  }
  return prop;
}

export const ExternalLinkTag = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
export const ExternalLink: FC<{ href: string, Tag?: any }> = ({ href, Tag = ExternalLinkTag, children }) => {
  return (
    <Tag href={href} target={'_blank'} rel={'noopener noreferrer'} onClick={() => {
      // send event to GA
      const eventArgs: EventArgs = {
        category: 'Navigation',
        action: 'Clicked on external Link',
        label: href,
      };
      ReactGa.event(eventArgs);
      console.log("ANALYTICS", eventArgs.category, eventArgs.action, eventArgs.label);
    }}>
      { children }
    </Tag>
  )
}