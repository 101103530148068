import {useRecoilState} from "recoil";
import {useEffect} from "react";
import {UserState,UserStateProps} from "../../state/User";

const LOCAL_STORAGE_KEY = 'de.agentur-boomer.website.store:CURRENT_USER';

export const useUserState = () => {
  const [state, setState] = useRecoilState(UserState);

  useEffect(() => {
    const currentUserObj = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (currentUserObj !== null) {
      const currentUser= JSON.parse(currentUserObj);
      setState(currentUser);
    }
  }, []);

  const setUserState = (user: UserStateProps) => {
    setState(user);
  }

  return {
    userState:state,
    setUserState,
  };
}
