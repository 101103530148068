import React, { FC, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../styles/Theme';
import { BREAKPOINT_LG, getDesktopProp, getMobileProp, ResponsiveType } from '../styles/GlobalStyle';

// export interface ButtonProps {
//   borderColor?: string;
// }
const ButtonStyle = css`
  background-color: ${Theme.colors.purple};
  box-sizing: border-box;
  // border: 1px solid {({ borderColor }) => borderColor ? borderColor : Theme.colors.green};
  // box-shadow: -5px 5px 0 {({ borderColor }) => borderColor ? borderColor : Theme.colors.green};
  border: 1px solid ${Theme.colors.green};
  box-shadow: -5px 5px 0 ${Theme.colors.green};
  border-radius: 50px;
  font-family: ${Theme.fonts.neueHelvetica};
  font-style: normal;
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 91.8%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  font-feature-settings: 'case' on;
  color: #FFFFFF;
  text-shadow: 0 0 0 ${Theme.colors.purple};
  filter: drop-shadow(0px 4px 4px #FFFFFF);
  padding: 1rem 2rem;
  margin-right: auto;
  cursor: pointer;
  text-transform: lowercase;
  transition: color 150ms ease-out, background-color 150ms ease-out, border-color 150ms ease-out;

  :hover {
    color: ${Theme.colors.green};
  }

  :active {
    background-color: ${Theme.colors.pink};
    border-color: ${Theme.colors.pink};
  }
  
  :disabled {
    // background-color: rgba({['r', 'g', 'b'].map((key) => (Theme.colors.rgb.purple as any)[key]).join(', ')}, .33);
    // border-color: rgba({['r', 'g', 'b'].map((key) => (Theme.colors.rgb.pink as any)[key]).join(', ')}, .33);
    background-color: rgba(30, 30, 30, .7);
    border-color: rgba(30, 30, 30, .7);
    box-shadow: -5px 5px 0 rgba(30, 30, 30, .3);
    color: rgba(230, 230, 230, .3);
    text-shadow: 0 0 0 rgba(230, 230, 230, .5);
    pointer-events: none;
    user-select: none;
  }
`;

export const Button = styled.button`
  ${ButtonStyle};
`;
export const LinkButton = styled.a`
  ${ButtonStyle};
  text-decoration: none;
`;

export const ChevronButton = styled.button<{ size?: ResponsiveType<string>, color?: string, direction?: 'left' | 'right' }>`
  background: none;
  border: none;

  display: flex;
  align-items: center;
  text-align: center;

  cursor: pointer;
  pointer-events: auto;

  width: ${({ size = '1.5rem' }) => getMobileProp(size)};
  height: ${({ size = '1.5rem' }) => getMobileProp(size)};

  ${BREAKPOINT_LG} {
    width: ${({ size = '1.5rem' }) => getDesktopProp(size)};
    height: ${({ size = '1.5rem' }) => getDesktopProp(size)};
  }

  position: relative;

  &::before {
    content: '';
    position: absolute;
    margin: auto;
    top: ${({ direction = 'right' }) => direction === 'left' ? '0' : '1px'};
    bottom: ${({ direction = 'right' }) => direction === 'right' ? '0' : '1px'};
    left: ${({ direction = 'right' }) => direction === 'left' ? '25%' : '0'};
    right: ${({ direction = 'right' }) => direction === 'right' ? '25%' : '0'};
    height: 2px;
    background-color: ${({ color = Theme.colors.purple }) => color};
    border-radius: 1px;
    transform: rotate(45deg);
    transform-origin: center ${({ direction = 'right' }) => direction};
  }

  &::after {
    content: '';
    position: absolute;
    margin: auto;
    top: ${({ direction = 'right' }) => direction === 'left' ? '1px' : '0'};
    bottom: ${({ direction = 'right' }) => direction === 'right' ? '1px' : '0'};
    left: ${({ direction = 'right' }) => direction === 'left' ? '25%' : '0'};
    right: ${({ direction = 'right' }) => direction === 'right' ? '25%' : '0'};
    height: 2px;
    background-color: ${({ color = Theme.colors.purple }) => color};
    border-radius: 1px;
    transform: rotate(-45deg);
    transform-origin: center ${({ direction = 'right' }) => direction};
  }
`;