import styled from 'styled-components';

export const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexboxVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const FlexboxVerticalCenter = styled(FlexboxVertical)`
  align-items: center;
`;