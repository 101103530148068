import { atom } from 'recoil';

export interface NavigationStateProps {
  isOpen: boolean;
}

export const NavigationState = atom<NavigationStateProps>({
  key: 'navigationState',
  default: {
    isOpen: false,
  },
});