import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../styles/Theme';
import { BREAKPOINT_LG, getDesktopProp, getMobileProp, ResponsiveType } from '../styles/GlobalStyle';

export interface TextBlockProps {
  textSize?: number;
  textColor?: string;
  marginBottom?: ResponsiveType<string>;
}

export const TextBlock = styled.p<TextBlockProps>`
  font-size: ${({ textSize }) => textSize ? `${textSize}rem` : '1.375rem'};
  color: ${({ textColor }) => textColor ? textColor : Theme.colors.purple};
  line-height: 2rem;
  font-family: ${Theme.fonts.neueHelvetica};
  font-style: normal;
  font-weight: 500;
  ${({ marginBottom }) => marginBottom && css`margin-bottom: ${getMobileProp(marginBottom)}`};

  ${BREAKPOINT_LG} {
    ${({ marginBottom }) => marginBottom && css`margin-bottom: ${getDesktopProp(marginBottom)}`};
  }
`;