import styled from 'styled-components';
import { PageSection, StyledSectionBoxedWrapper } from '../styles/PageSection';
import { Theme } from '../styles/Theme';
import { TextBlock } from './TextBlock';
import { BREAKPOINT_LG, ContainedImage, ExternalLink } from '../styles/GlobalStyle';
import { FlexboxVertical } from '../styles/Layouts';
import { CookieConsent } from './CookieConsent';
import { useCookiesAllowed } from '../state/CookieConsent';
import { useEffect } from 'react';
import ReactGa from 'react-ga';
import { Link, useLocation } from 'react-router-dom';

const StyledFooter = styled.footer``;

const LogoBoxedWrapper = styled(StyledSectionBoxedWrapper)`
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 1.5rem;
`;

const FooterLogoBig = styled(ContainedImage)`
  width: auto;
  height: 3rem;

  ${BREAKPOINT_LG} {
    height: 8rem;
  }

  &:not(:last-child) {
    margin-right: .75rem;

    ${BREAKPOINT_LG} {
      margin-right: 1.5rem;
    }
  }
`;

const BoxedWrapper = styled(StyledSectionBoxedWrapper)`
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  & > * {
    flex: 1;

    &:first-child {
      flex: 2;
      justify-content: space-between;
      align-items: flex-start;
    }

    ${BREAKPOINT_LG} {
      justify-content: flex-end;
      align-items: center;
    }
  }

  padding: 5rem 1.5rem;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {;
      color: ${Theme.colors.pink};
      cursor: pointer;
    }
  }
`;

const FooterLogoGlobe = styled(ContainedImage)`
  width: auto;
  height: 4.5rem;

  margin-right: auto;
  margin-bottom: 3.5rem;

  ${BREAKPOINT_LG} {
    height: 8.625rem;
  }
`;

const ResponsiveFlexbox = styled(FlexboxVertical)`
  & > * {
    margin: 0 0 2rem 0;

    ${BREAKPOINT_LG} {
      margin: 0 3.5rem 0 0;
    }
  }

  ${BREAKPOINT_LG} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const prodGAId = 'G-X7ZWV2CVFB';
const intGAId = 'G-37ZCTN9970';

export const PageFooter = () => {
  // const location = useLocation();
  const cookiesAllowed = useCookiesAllowed();

  useEffect(() => {
    const isProd = () => {
      return window.location.origin === 'https://agentur-boomer.de';
    };
    if (cookiesAllowed) {
      ReactGa.initialize(isProd() ? prodGAId : intGAId);
      ReactGa.pageview(window.location.pathname + window.location.search);
    }
  }, [cookiesAllowed]);

  return (
    <StyledFooter>
      <CookieConsent />

      <PageSection
        minHeight={{ mobile: '', desktop: 'calc(50vh - 5.875rem)' }}
        backgroundColor={Theme.colors.rgb.purple}>
        <LogoBoxedWrapper>
          <FooterLogoBig src={'/images/boomer-logo-footer.gif'} alt={'Boomer Logo'} />
          <FooterLogoBig src={'/images/x.svg'} alt={'VS'} />
          <ExternalLink href={'https://brandneo.de/'}>
            <FooterLogoBig src={'/images/brandneo_logo.png'} alt={'brandneo logo'} />
          </ExternalLink>
        </LogoBoxedWrapper>
      </PageSection>

      <PageSection
        minHeight={{ mobile: '', desktop: '50vh' }}
        backgroundColor={Theme.colors.rgb.green}
      >
        <BoxedWrapper>
          <FlexboxVertical>
            <FooterLogoGlobe src={'/images/globe-small.png'} alt={'Boomer Logo'} />
            <ResponsiveFlexbox>
              <TextBlock textColor={Theme.colors.purple}>
                Agentur Boomer
                <br />
                Hamburg
              </TextBlock>
              <TextBlock textColor={Theme.colors.purple}>
                <Link to={'/imprint'}>Impressum</Link>
                <br />
                <Link to={'/disclaimer'}>Datenschutz</Link>
              </TextBlock>
              <TextBlock textColor={Theme.colors.purple}>
                All rights reserved
                <br />
                {new Date().getFullYear()}
              </TextBlock>
            </ResponsiveFlexbox>
          </FlexboxVertical>
          <FlexboxVertical>
            <TextBlock textColor={Theme.colors.purple}>
              <ExternalLink href={'https://www.instagram.com/agentur_boomer/'}>Instagram</ExternalLink>
              <br />
              <ExternalLink href={'https://www.tiktok.com/@agentur_boomer/'}>tik tok</ExternalLink>
              <br />
              <ExternalLink href={'https://www.linkedin.com/company/agenturboomer/'}>Linkedin</ExternalLink>
            </TextBlock>
          </FlexboxVertical>
        </BoxedWrapper>
      </PageSection>
    </StyledFooter>
  );
};
