import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RgbColor, Theme } from '../styles/Theme';
import { BREAKPOINT_LG, getDesktopProp, getMobileProp, ResponsiveType } from '../styles/GlobalStyle';

export interface HeadlineProps extends HeadlineStyleProps {
  level: number;
}

export interface HeadlineStyleProps {
  textColor?: RgbColor;
  textOpacity?: number;
  textAlign?: ResponsiveType<'left' | 'right' | 'center'>;
  borderColor?: RgbColor;
  borderOpacity?: number;
  borderSize?: number;
  marginBottom?: ResponsiveType<string>;
  letterSpacing?: ResponsiveType<string>;
  singleLine?: ResponsiveType<boolean>;
  lineHeight?: ResponsiveType<string>;
}

const headlineStyle = css<HeadlineStyleProps>`
  color: ${({
              textColor,
              textOpacity,
            }) => `rgba(${textColor ? textColor.r : 255},${textColor ? textColor.g : 255},${textColor ? textColor.b : 255}, ${textOpacity ? textOpacity : 1})`};
  -webkit-text-stroke-width: ${({ borderSize }) => borderSize ? `${borderSize}px` : '1px'};
  -webkit-text-stroke-color: ${({
                                  borderColor,
                                  borderOpacity,
                                }) => `rgba(${borderColor ? borderColor.r : 255},${borderColor ? borderColor.g : 255},${borderColor ? borderColor.b : 255}, ${borderOpacity !== undefined ? borderOpacity : borderColor ? 1 : 0})`};
  text-transform: uppercase;
  text-align: ${({ textAlign = 'left' }) => getMobileProp(textAlign)};

  // boomer word break style
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;

  ${({ lineHeight }) => lineHeight && css`line-height: ${getMobileProp(lineHeight)}`};
  ${({ marginBottom }) => marginBottom && css`margin-bottom: ${getMobileProp(marginBottom)}`};
  ${({ letterSpacing }) => letterSpacing && css`letter-spacing: ${getMobileProp(letterSpacing)}`};
  ${({ singleLine }) => singleLine !== undefined && getMobileProp(singleLine) && css`white-space: nowrap;
    word-break: keep-all;`};

  ${BREAKPOINT_LG} {
    text-align: ${({ textAlign = 'left' }) => getDesktopProp(textAlign)};
    ${({ lineHeight }) => lineHeight && css`line-height: ${getDesktopProp(lineHeight)}`};
    ${({ marginBottom }) => marginBottom && css`margin-bottom: ${getDesktopProp(marginBottom)}`};
    ${({ letterSpacing }) => letterSpacing && css`letter-spacing: ${getDesktopProp(letterSpacing)}`};
    ${({ singleLine }) => singleLine !== undefined && getDesktopProp(singleLine) && css`white-space: nowrap;
      word-break: keep-all;`};
  }
`;

const HeadlineLevel1 = styled.h1<HeadlineStyleProps>`
  ${headlineStyle};
  font-size: ${Theme.fontSizes.headlinesResponsive.h1};
  font-family: ${Theme.fonts.monumentExtendedBold};
  padding: 1rem;

  ${BREAKPOINT_LG} {
    font-size: ${Theme.fontSizes.headlines.h1};
  }
`;

const HeadlineLevel2 = styled.h2<HeadlineStyleProps>`
  ${headlineStyle};
  font-size: ${Theme.fontSizes.headlinesResponsive.h2};
  font-family: ${Theme.fonts.monumentExtendedRegular};

  ${BREAKPOINT_LG} {
    font-size: ${Theme.fontSizes.headlines.h2};
  }
`;

const HeadlineLevel3 = styled.h3<HeadlineStyleProps>`
  ${headlineStyle};
  font-size: ${Theme.fontSizes.headlinesResponsive.h3};
  font-family: ${Theme.fonts.monumentExtendedRegular};

  ${BREAKPOINT_LG} {
    font-size: ${Theme.fontSizes.headlines.h3};
  }
`;

const HeadlineLevel4 = styled.h4<HeadlineStyleProps>`
  ${headlineStyle};
  font-size: ${Theme.fontSizes.headlinesResponsive.h4};
  font-family: ${Theme.fonts.monumentExtendedRegular};

  ${BREAKPOINT_LG} {
    font-size: ${Theme.fontSizes.headlines.h4};
  }
`;

const HeadlineLevel5 = styled.h5<HeadlineStyleProps>`
  ${headlineStyle};
  font-size: ${Theme.fontSizes.headlinesResponsive.h5};
  font-family: ${Theme.fonts.monumentExtendedRegular};

  ${BREAKPOINT_LG} {
    font-size: ${Theme.fontSizes.headlines.h5};
  }
`;

const HeadlineLevel6 = styled.h6<HeadlineStyleProps>`
  ${headlineStyle};
  font-size: ${Theme.fontSizes.headlinesResponsive.h6};
  font-family: ${Theme.fonts.monumentExtendedBold};

  ${BREAKPOINT_LG} {
    font-size: ${Theme.fontSizes.headlines.h6};
  }
`;

export const Headline: FC<HeadlineProps> = (props) => {
  const { level } = props;
  return (
    <>
      {
        level === 1 &&
        <HeadlineLevel1 {...props}>
          {props.children}
        </HeadlineLevel1>
      }
      {
        level === 2 &&
        <HeadlineLevel2 {...props}>
          {props.children}
        </HeadlineLevel2>
      }
      {
        level === 3 &&
        <HeadlineLevel3 {...props}>
          {props.children}
        </HeadlineLevel3>
      }
      {
        level === 4 &&
        <HeadlineLevel4 {...props}>
          {props.children}
        </HeadlineLevel4>
      }
      {
        level === 5 &&
        <HeadlineLevel5 {...props}>
          {props.children}
        </HeadlineLevel5>
      }
      {
        level === 6 &&
        <HeadlineLevel6 {...props}>
          {props.children}
        </HeadlineLevel6>
      }
    </>
  );
};