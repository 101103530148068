import React, { FC } from 'react';
import styled from 'styled-components';
import { Theme } from '../../styles/Theme';
import { BREAKPOINT_LG } from '../../styles/GlobalStyle';
import { InputWrapper, StyledLabel } from './FormStyles';

export interface InputProps {
  id: string;
  label: string;
  disabled?: boolean;
  value: string | null;
  onValueChanged: (value: string) => void;
  error: boolean;
  type?: string;
}

const StyledInput = styled.input<{ error: boolean }>`
  border: 2px solid ${({ error }) => error ? Theme.colors.pink : Theme.colors.green};
  color: ${Theme.colors.purple};

  font-family: ${Theme.fonts.neueHelvetica};
  font-style: normal;
  font-weight: bold;

  padding: .5rem .75rem;

  font-size: 1rem;
  line-height: 1.5rem;

  ${BREAKPOINT_LG} {
    font-size: 1.375rem;
    line-height: 2rem;
  }

  &::placeholder {
    color: rgba(0, 0, 0, .4);
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Helvetica Neue';
  }
`;

export const Input: FC<InputProps> = (props) => {
  const { id, label, disabled, value, onValueChanged, error, type } = props;

  let inputType = type;
  if (!inputType) {
    inputType = 'text';
  }

  return (
    <InputWrapper disabled={disabled}>
      {
        (label && value) && <StyledLabel htmlFor={id} error={error}>{label}</StyledLabel>
      }
      <StyledInput
        error={error}
        id={id}
        placeholder={label.replace('*', '')}
        value={value || ''}
        type={inputType}
        disabled={disabled || false}
        onChange={(event) => {
          onValueChanged(event.target.value);
        }} />
    </InputWrapper>
  );
};