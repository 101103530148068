import styled, { css } from 'styled-components';
import { Theme } from '../../styles/Theme';
import { BREAKPOINT_LG } from '../../styles/GlobalStyle';

export const disabledStyle = css`
  opacity: .7;
  pointer-events: none;
  user-select: none;
`;

export const InputWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  user-select: none;
  cursor: pointer;
  position: relative;
  
  ${({ disabled = false }) => disabled && disabledStyle};
`;

export const StyledLabel = styled.label<{ error?: boolean }>`
  position: absolute;
  color: ${({ error = false }) => error ? Theme.colors.pink : Theme.colors.purple};
  font-family: ${Theme.fonts.neueHelvetica};
  font-style: normal;
  font-weight: bold;
  font-size: .875rem;
  line-height: 1.5rem;
  top: -0.75rem;
  left: .75rem;
  padding: 0 .25rem;
  background: #fff;
  
`;