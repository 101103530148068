import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export interface UserStateProps {
  currentUser: {
    id: string ;
    firstName: string ;
    lastName: string ;
    jwt: string ;
  } | null
}

export const UserState = atom<UserStateProps>({
  key: 'userState',
  default: {
    currentUser: null
  },
  effects_UNSTABLE: [persistAtom],
});


