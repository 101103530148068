import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { NavigationState, NavigationStateProps } from '../state/Navigation';
import styled from 'styled-components';
import { Theme } from '../styles/Theme';
import { Headline } from './Headline';
import { TextBlock } from './TextBlock';
import { BREAKPOINT_LG, DIMEN_BOXED_CONTENT_WIDTH } from '../styles/GlobalStyle';
import {Link} from 'react-router-dom';
import {useUserState} from "../utils/hooks/useUser";
import {AFTER_LOGOUT_REDIRECTION_ROUTE} from  '../utils/Redirecting';
import {useHistory} from "react-router-dom";

const NavigationWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  
  width: 100%;
  pointer-events: all;
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  
  background: ${Theme.colors.purple};
  
  ${BREAKPOINT_LG} {
    max-width: 50vw;
  }
`;

const BoxedContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  
  padding: 1rem 1.5rem;
  
  ${BREAKPOINT_LG} {
    width: 100%;
    max-width: ${DIMEN_BOXED_CONTENT_WIDTH/2}px;
    margin-right: auto;

    padding: 1.25rem 5rem 5.75rem;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  padding: 1rem 0;
  overflow: auto;
  
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
      color: ${Theme.colors.pink};

      ::before {
        content: '/';
      }
    }
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  
  padding-right: 1rem;
  margin-right: -1.5rem;
  
  ${BREAKPOINT_LG} {
    margin-right: -5rem;
  }
`;
const CloseButton = styled.button`
  margin-left: auto;
  border: none;
  background: transparent;
  cursor: pointer;

  transition: color 150ms ease-out;
  color: ${Theme.colors.green};
  
  &:hover {
    color: ${Theme.colors.pink};
  }
  
  svg {
    width: 1.5rem;
    height: 1.5rem;
    
    ${BREAKPOINT_LG} {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const Navigation: FC = (props) => {
  const {userState,setUserState} = useUserState();
  const [navigationState, setNavigationState] = useRecoilState<NavigationStateProps>(NavigationState);
  const {currentUser} =userState;
  const history= useHistory();

  return (
    <>
    {
      navigationState.isOpen &&
      <NavigationWrapper>
        <BoxedContentWrapper>
          <CloseButtonWrapper>
            <CloseButton onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setNavigationState({ isOpen: false });
            }}>
              {/*<Icon*/}
              {/*  size={{ mobile: '1.5rem', desktop: '3rem' }}*/}
              {/*  src={'/images/menu-close.svg'}*/}
              {/*  alt='Hamburger Menu'*/}
              {/*/>*/}
              <svg width="47" height="42" viewBox="0 0 47 42" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0126 35.4596C14.9045 32.9344 16.8923 30.4405 18.9793 28.0934C20.896 25.6571 23.0969 23.4589 25.5355 21.5454C28.0076 19.8466 30.9018 18.7626 33.4244 17.1196C36.1027 15.3693 38.6573 13.4268 41.1943 11.4833C41.8021 10.9238 42.165 10.147 42.2046 9.32064C42.2441 8.49425 41.9573 7.68451 41.4059 7.06613C40.8398 6.46508 40.0636 6.10599 39.2402 6.06417C38.4168 6.02235 37.6101 6.30105 36.9893 6.84181C34.5471 8.7965 32.0035 10.6213 29.3692 12.3085C26.5774 14.0265 23.4837 15.2066 20.8895 17.2357C18.5171 19.2587 16.3326 21.4922 14.3628 23.9093C12.0494 26.5459 9.82848 29.2847 7.72787 32.1005C7.50837 32.447 7.35938 32.8335 7.28938 33.238C7.21937 33.6425 7.22974 34.0571 7.31989 34.458C7.41004 34.859 7.57819 35.2384 7.81477 35.5748C8.05136 35.9112 8.35174 36.1978 8.69874 36.4184C9.04574 36.6389 9.43257 36.7891 9.83716 36.8603C10.2417 36.9315 10.6562 36.9223 11.0567 36.8332C11.4573 36.7442 11.8362 36.5771 12.1718 36.3414C12.5074 36.1057 12.7931 35.8061 13.0126 35.4596Z" fill="currentColor"/>
                <path d="M10.4489 7.9038C19.7194 16.8016 28.5909 26.1249 37.0212 35.8296C40.2939 39.6029 44.8675 35.324 41.5947 31.5508C33.1645 21.8461 24.293 12.5227 15.0224 3.62497C14.3106 2.88761 13.3983 2.40292 12.4768 2.27255C11.5553 2.14218 10.6963 2.37626 10.0799 2.92571C9.48042 3.49767 9.18314 4.34024 9.25217 5.27157C9.3212 6.20289 9.75101 7.1483 10.4489 7.9038Z" fill="currentColor"/>
              </svg>
            </CloseButton>
          </CloseButtonWrapper>

          <StyledNav>
            <Headline level={4} textColor={Theme.colors.rgb.green} borderColor={Theme.colors.rgb.green}>
              <Link to={'/'} onClick={(event) => {
                setNavigationState({ isOpen: false });
              }}>Home</Link>
            </Headline>

            <Headline level={4} textColor={Theme.colors.rgb.green} borderColor={Theme.colors.rgb.green}>
              <Link to={'/jobs'} onClick={(event) => {
                setNavigationState({ isOpen: false });
              }}>Jobs</Link>
            </Headline>
            {
              !currentUser &&
              <Headline level={4} textColor={Theme.colors.rgb.green} borderColor={Theme.colors.rgb.green}>
                <Link to={'/login'} onClick={(event) => {
                  setNavigationState({ isOpen: false });
                }}>Anmelden</Link>
              </Headline>
            }

            {
              currentUser && currentUser.id &&
              <Headline level={4} textColor={Theme.colors.rgb.green} borderColor={Theme.colors.rgb.green}>
                <Link to={'/profile'} onClick={(event) => {
                  setNavigationState({ isOpen: false });
                }}>Mein Konto</Link>
              </Headline>
            }

            {
              currentUser && currentUser.id &&
              <Headline level={4} textColor={Theme.colors.rgb.green} borderColor={Theme.colors.rgb.green}>
                <Link to={`${AFTER_LOGOUT_REDIRECTION_ROUTE}`} onClick={(event) => {
                  setUserState( {currentUser:null});
                  setNavigationState({ isOpen: false });
                  history.push(AFTER_LOGOUT_REDIRECTION_ROUTE);
                }}>Logout</Link>
              </Headline>
            }

          </StyledNav>

          <TextBlock textColor={Theme.colors.green}>
            All rights reserved
            <br />
            {new Date().getFullYear()}
          </TextBlock>
        </BoxedContentWrapper>
      </NavigationWrapper>
    }
    </>
  );
}