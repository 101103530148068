import { PageSection, StyledSectionBoxedWrapper } from '../styles/PageSection';
import { PageHeader } from '../components/PageHeader';
import { Theme } from '../styles/Theme';
import { PageFooter } from '../components/PageFooter';
import styled from 'styled-components';
import React, { FC } from 'react';

const DisclaimerWrapper = styled(StyledSectionBoxedWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  word-break: break-word;

  padding: 2rem 1.5rem;

  font-size: 1.375rem;
  color: ${Theme.colors.purple};
  line-height: 2rem;
  font-family: ${Theme.fonts.neueHelvetica};
  font-style: normal;
  font-weight: 500;

  h1, h2, h3, h4 {
    color: ${Theme.colors.pink};
  }

  h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin: 0 0 1rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 2.5rem 0 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1.5rem 0 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 1.5rem 0 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin: 0 0 1rem 1.5rem;
  }
`;

export const Disclaimer: FC = () => {
  return (
    <>

      <PageSection backgroundColor={Theme.colors.rgb.white}>
        <DisclaimerWrapper>

          <h1>Datenschutz­erklärung</h1>
          <h2>1. Datenschutz auf einen Blick</h2>
          <h3>Allgemeine Hinweise</h3> <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
          personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
          denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
          Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
          <h3>Datenerfassung auf dieser Website</h3> <h4>Wer ist verantwortlich für die Datenerfassung auf dieser
          Website?</h4> <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
          Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.</p> <h4>Wie erfassen wir Ihre Daten?</h4> <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie
          uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
          <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
          <h4>Wofür nutzen wir Ihre Daten?</h4> <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
          <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4> <p>Sie haben jederzeit das Recht, unentgeltlich
          Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
          außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
          haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen
          Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
          <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
          <h3>Analyse-Tools und Tools von Dritt­anbietern</h3> <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten
          statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.</p> <p>Detaillierte
          Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.</p>
          <h2>2. Hosting</h2>
          <h3>Externes Hosting</h3> <p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die
          personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters
          gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert
          werden, handeln.</p> <p>Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren
          potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen
          und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1
          lit. f DSGVO).</p> <p>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
          Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p> <p>Wir setzen
          folgenden Hoster ein:</p>
          <p>DigitalOcean</p>
          <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
          <h3>Datenschutz</h3> <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
          behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerklärung.</p> <p>Wenn Sie diese Website benutzen, werden verschiedene
          personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert
          werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
          nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p> <p>Wir weisen darauf hin, dass die
          Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
          <h3>Hinweis zur verantwortlichen Stelle</h3> <p>Die verantwortliche Stelle für die Datenverarbeitung auf
          dieser Website ist:</p> <p>brandneo GmbH<br />
          Am Kai 10<br />
          44263 Dortmund<br />
          Deutschland</p>

          <p>Telefon: +49 231 58 6881-0<br />
            E-Mail: info@brandneo.de</p>
          <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
            über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.</p>

          <h3>Speicherdauer</h3> <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt
          wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn
          Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
          werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
          personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten
          Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
          <h3>Datenschutz­beauftragter</h3> <p>Wir haben für unser Unternehmen einen Datenschutzbeauftragten
          bestellt.</p>
          <p>DataCo GmbH<br />
            Dachauer Straße 65<br />
            80335 München<br />
            Deutschland</p>

          <p>Telefon: +49 89 7400 45840<br />
            E-Mail: info@dataguard.de</p>
          <h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3> <p>Wir verwenden unter anderem Tools
          von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
          diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort
          verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares
          Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
          personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen
          gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
          Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und
          dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.</p><h3>Widerruf Ihrer
          Einwilligung zur Datenverarbeitung</h3> <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit
          der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
          <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21
            DSGVO)</h3> <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN
          SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
          IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
          PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
          DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
          MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE
          IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p> <p>WERDEN IHRE
          PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
          WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
          EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN
          SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
          VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
          <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3> <p>Im Falle von Verstößen gegen die DSGVO
          steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
          gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
          Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
          <h3>Recht auf Daten­übertrag­barkeit</h3> <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in
          einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
          an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
          <h3>SSL- bzw. TLS-Verschlüsselung</h3> <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
          Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
          dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
          Browserzeile.</p> <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
          übermitteln, nicht von Dritten mitgelesen werden.</p>
          <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3> <p>Besteht nach dem Abschluss eines
          kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z. B. Kontonummer bei
          Einzugsermächtigung) zu übermitteln, werden diese Daten zur Zahlungsabwicklung benötigt.</p> <p>Der
          Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt
          ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
          Ihrer Browserzeile.</p> <p>Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns
          übermitteln, nicht von Dritten mitgelesen werden.</p>
          <h3>Auskunft, Löschung und Berichtigung</h3> <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
          jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
          Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
          dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
          uns wenden.</p>
          <h3>Recht auf Einschränkung der Verarbeitung</h3> <p>Sie haben das Recht, die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf
          Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
          <ul>
            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
              in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der
              Löschung die Einschränkung der Datenverarbeitung verlangen.
            </li>
            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung
              oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren
              und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
              haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer
            Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
            werden.</p>
          <h3>Widerspruch gegen Werbe-E-Mails</h3> <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird
          hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
          unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
          <h2>4. Datenerfassung auf dieser Website</h2>
          <h3>Cookies</h3> <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und
          richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
          (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden
          nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
          Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.</p> <p>Teilweise
          können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des
          Drittunternehmens (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</p> <p>Cookies haben
          verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne
          diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies
          dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.</p> <p>Cookies, die zur Durchführung des
          elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung der Website
          (z. B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
          berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten
          Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt
          die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit.
          a DSGVO); die Einwilligung ist jederzeit widerrufbar.</p> <p>Sie können Ihren Browser so einstellen, dass Sie
          über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies
          für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
          Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt
          sein.</p> <p>Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie
          hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.</p>
          <h3>Kontaktformular</h3> <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus
          dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und
          für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
          weiter.</p> <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
          Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
          effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p> <p>Die von Ihnen im
          Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung
          zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener
          Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
          unberührt.</p>
          <h3>Anfrage per E-Mail, Telefon oder Telefax</h3> <p>Wenn Sie uns per E-Mail, Telefon oder Telefax
          kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage)
          zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht
          ohne Ihre Einwilligung weiter.</p> <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
          DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p> <p>Die von
          Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern,
          Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
          abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.</p>
          <h3>Registrierung auf dieser Website</h3> <p>Sie können sich auf dieser Website registrieren, um zusätzliche
          Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des
          jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung abgefragten
          Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.</p>
          <p>Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die
            bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.</p> <p>Die
          Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchführung des durch die
          Registrierung begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6 Abs. 1 lit. b
          DSGVO).</p> <p>Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf dieser
          Website registriert sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
          unberührt.</p>
          <h2>5. Analyse-Tools und Werbung</h2>
          <h3>Google Analytics</h3> <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter
          ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p> <p>Google
          Analytics ermöglicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren. Hierbei erhält
          der Websitebetreiber verschiedene Nutzungsdaten, wie z. B. Seitenaufrufe, Verweildauer, verwendete
          Betriebssysteme und Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem Profil zusammengefasst,
          das dem jeweiligen Nutzer bzw. dessen Endgerät zugeordnet ist.</p> <p>Des Weiteren können wir mit Google
          Analytics u. a. Ihre Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet Google Analytics
          verschiedene Modellierungsansätze, um die erfassten Datensätze zu ergänzen und setzt Machine
          Learning-Technologien bei der Datenanalyse ein.</p>
          <p>Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des
            Nutzerverhaltens ermöglichen (z. B. Cookies oder Device-Fingerprinting). Die von Google erfassten
            Informationen über die Benutzung dieser Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert.</p> <p>Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens,
          um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung
          abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
          auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p> <p>Die
          Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden
          Sie hier: <a href='https://privacy.google.com/businesses/controllerterms/mccs/' target='_blank'
                       rel='noopener noreferrer'>https://privacy.google.com/businesses/controllerterms/mccs/</a>.</p>
          <h4>Browser Plugin</h4> <p>Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern,
          indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a
            href='https://tools.google.com/dlpage/gaoptout?hl=de' target='_blank'
            rel='noopener noreferrer'>https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p> <p>Mehr Informationen zum
          Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: <a
            href='https://support.google.com/analytics/answer/6004245?hl=de' target='_blank'
            rel='noopener noreferrer'>https://support.google.com/analytics/answer/6004245?hl=de</a>.</p><h4>Google
          Analytics E-Commerce-Messung</h4> <p>Diese Website nutzt die Funktion „E-Commerce-Messung“ von Google
          Analytics. Mit Hilfe von E-Commerce-Messung kann der Websitebetreiber das Kaufverhalten der Websitebesucher
          zur Verbesserung seiner Online-Marketing-Kampagnen analysieren. Hierbei werden Informationen, wie zum Beispiel
          die getätigten Bestellungen, durchschnittliche Bestellwerte, Versandkosten und die Zeit von der Ansicht bis
          zum Kauf eines Produktes erfasst. Diese Daten können von Google unter einer Transaktions-ID zusammengefasst
          werden, die dem jeweiligen Nutzer bzw. dessen Gerät zugeordnet ist.</p>
          <h4>Speicherdauer</h4> <p>Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit Cookies,
          Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind,
          werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter folgendem Link: <a
            href='https://support.google.com/analytics/answer/7667196?hl=de' target='_blank'
            rel='noopener noreferrer'>https://support.google.com/analytics/answer/7667196?hl=de</a></p>
          <h3>Facebook Pixel</h3> <p>Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel von Facebook.
          Anbieter dieses Dienstes ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die
          erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere Drittländer
          übertragen.</p> <p>So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem diese durch Klick auf
          eine Facebook-Werbeanzeige auf die Website des Anbieters weitergeleitet wurden. Dadurch können die Wirksamkeit
          der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke ausgewertet werden und zukünftige
          Werbemaßnahmen optimiert werden.</p> <p>Die erhobenen Daten sind für uns als Betreiber dieser Website anonym,
          wir können keine Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten werden aber von Facebook
          gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook die
          Daten für eigene Werbezwecke, entsprechend der <a href='https://de-de.facebook.com/about/privacy/'
                                                            target='_blank'
                                                            rel='noopener noreferrer'>Facebook-Datenverwendungsrichtlinie</a> verwenden
          kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook sowie außerhalb von
          Facebook ermöglichen. Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst werden.
        </p> <p>Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
          Websitebetreiber hat ein berechtigtes Interesse an effektiven Werbemaßnahmen unter Einschluss der sozialen
          Medien. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
          Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.</p> <p>Die Datenübertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: <a
            href='https://www.facebook.com/legal/EU_data_transfer_addendum' target='_blank'
            rel='noopener noreferrer'>https://www.facebook.com/legal/EU_data_transfer_addendum</a> und <a
            href='https://de-de.facebook.com/help/566994660333381' target='_blank'
            rel='noopener noreferrer'>https://de-de.facebook.com/help/566994660333381</a>.</p> <p>Soweit mit Hilfe des
          hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet
          werden, sind wir und die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
          gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit
          beschränkt sich dabei ausschließlich auf die Erfassung der Daten und deren Weitergabe an Facebook. Die nach
          der Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil der gemeinsamen Verantwortung. Die uns
          gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung festgehalten.
          Den Wortlaut der Vereinbarung finden Sie unter: <a href='https://www.facebook.com/legal/controller_addendum'
                                                             target='_blank'
                                                             rel='noopener noreferrer'>https://www.facebook.com/legal/controller_addendum</a>.
          Laut dieser Vereinbarung sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des
          Facebook-Tools und für die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website
          verantwortlich. Für die Datensicherheit der Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte
          (z. B. Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei Facebook
          geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an
          Facebook weiterzuleiten.</p> <p>In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum
          Schutz Ihrer Privatsphäre: <a href='https://de-de.facebook.com/about/privacy/' target='_blank'
                                        rel='noopener noreferrer'>https://de-de.facebook.com/about/privacy/</a>.</p>
          <p>Sie können außerdem die Remarketing-Funktion „Custom Audiences“ im Bereich Einstellungen für Werbeanzeigen
            unter <a href='https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen' target='_blank'
                     rel='noopener noreferrer'>https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a> deaktivieren.
            Dazu müssen Sie bei Facebook angemeldet sein.</p> <p>Wenn Sie kein Facebook Konto besitzen, können Sie
          nutzungsbasierte Werbung von Facebook auf der Website der European Interactive Digital Advertising Alliance
          deaktivieren: <a href='http://www.youronlinechoices.com/de/praferenzmanagement/' target='_blank'
                           rel='noopener noreferrer'>http://www.youronlinechoices.com/de/praferenzmanagement/</a>.</p>
          <h2>6. Newsletter</h2>
          <h3>Newsletter­daten</h3> <p>Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen
          wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der
          Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten
          werden nicht bzw. nur auf freiwilliger Basis erhoben. Für die Abwicklung der Newsletter nutzen wir
          Newsletterdiensteanbieter, die nachfolgend beschrieben werden.</p>
          <h3>Mailjet</h3> <p>Diese Website nutzt Mailjet für den Versand von Newslettern. Anbieter ist die Mailgun
          Technologies Inc., 112 E Pecan Sr. #1135, San Antonio, Texas 78205, USA.</p> <p>Mailjet ist ein Dienst, mit
          dem u.a. der Versand von Newslettern organisiert und analysiert werden kann. Die von Ihnen zum Zwecke des
          Newsletterbezugs eingegeben Daten werden auf den Servern von Mailjet gespeichert.</p> <h4>Datenanalyse durch
          Mailjet</h4> <p>Mit Hilfe von Mailjet ist es uns möglich, unsere Newsletter-Kampagnen zu analysieren. So
          können wir z. B. sehen, ob eine Newsletter-Nachricht geöffnet wurde und welche Links ggf. angeklickt wurden.
          Auf diese Weise können wir u.a. feststellen, welche Links besonders oft angeklickt wurden.</p> <p>Außerdem
          können wir erkennen, ob nach dem Öffnen/ Anklicken bestimmte vorher definierte Aktionen durchgeführt wurden
          (Conversion-Rate). Wir können so z. B. erkennen, ob Sie nach dem Anklicken des Newsletters einen Kauf getätigt
          haben.</p> <p>Mailjet ermöglicht es uns auch, die Newsletter-Empfänger anhand verschiedener Kategorien zu
          unterteilen („clustern“). Dabei lassen sich die Newsletterempfänger z. B. nach Alter, Geschlecht oder Wohnort
          unterteilen. Auf diese Weise lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen. Wenn
          Sie keine Analyse durch Mailjet wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in jeder
          Newsletternachricht einen entsprechenden Link zur Verfügung.</p> <p>Ausführliche Informationen zu den
          Funktionen von Mailjet entnehmen Sie folgendem Link: <a href='https://www.mailjet.de/funktion/'
                                                                  target='_blank'
                                                                  rel='noopener noreferrer'>https://www.mailjet.de/funktion/</a>.
        </p> <p>Die Datenschutzerklärung von Mailjet finden Sie unter: <a
          href='https://www.mailjet.de/sicherheit-datenschutz/' target='_blank'
          rel='noopener noreferrer'>https://www.mailjet.de/sicherheit-datenschutz/</a>.</p> <h4>Rechtsgrundlage</h4>
          <p>Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können
            diese Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
            bleibt vom Widerruf unberührt.</p> <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier: <a href='https://www.mailjet.de/av-vertrag/'
                                                                  target='_blank'
                                                                  rel='noopener noreferrer'>https://www.mailjet.de/av-vertrag/</a>.
        </p> <h4>Speicherdauer</h4> <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden
          von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und
          nach der Abbestellung des Newsletters aus der Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken
          bei uns gespeichert wurden, bleiben hiervon unberührt.</p> <p>Nach Ihrer Austragung aus der
          Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
          Blacklist gespeichert, um künftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur für diesen
          Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch
          unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
          Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht
          befristet. <strong>Sie können der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
            Interesse überwiegen.</strong></p>
          <h2>7. Plugins und Tools</h2>
          <h3>Google Web Fonts (lokales Hosting)</h3> <p>Diese Seite nutzt zur einheitlichen Darstellung von
          Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Die Google Fonts sind lokal
          installiert. Eine Verbindung zu Servern von Google findet dabei nicht statt.</p> <p>Weitere Informationen zu
          Google Web Fonts finden Sie unter <a href='https://developers.google.com/fonts/faq' target='_blank'
                                               rel='noopener noreferrer'>https://developers.google.com/fonts/faq</a> und
          in der Datenschutzerklärung von Google: <a href='https://policies.google.com/privacy?hl=de' target='_blank'
                                                     rel='noopener noreferrer'>https://policies.google.com/privacy?hl=de</a>.
        </p>
          <h3>Adobe Fonts</h3> <p>Diese Website nutzt zur einheitlichen Darstellung bestimmter Schriftarten Web Fonts
          von Adobe. Anbieter ist die Adobe Systems Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA
          (Adobe).</p> <p>Beim Aufruf dieser Website lädt Ihr Browser die benötigten Schriftarten direkt von Adobe, um
          sie Ihrem Endgerät korrekt anzeigen zu können. Dabei stellt Ihr Browser eine Verbindung zu den Servern von
          Adobe in den USA her. Hierdurch erlangt Adobe Kenntnis darüber, dass über Ihre IP-Adresse diese Website
          aufgerufen wurde. Bei der Bereitstellung der Schriftarten werden nach Aussage von Adobe keine Cookies
          gespeichert.</p> <p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes
          auf seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
          Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
          DSGVO; die Einwilligung ist jederzeit widerrufbar.</p> <p>Die Datenübertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: <a
            href='https://www.adobe.com/de/privacy/eudatatransfers.html' target='_blank'
            rel='noopener noreferrer'>https://www.adobe.com/de/privacy/eudatatransfers.html</a>.</p> <p>Nähere
          Informationen zu Adobe Fonts erhalten Sie unter: <a
            href='https://www.adobe.com/de/privacy/policies/adobe-fonts.html' target='_blank'
            rel='noopener noreferrer'>https://www.adobe.com/de/privacy/policies/adobe-fonts.html</a>.</p> <p>Die
          Datenschutzerklärung von Adobe finden Sie unter: <a href='https://www.adobe.com/de/privacy/policy.html'
                                                              target='_blank'
                                                              rel='noopener noreferrer'>https://www.adobe.com/de/privacy/policy.html</a>
        </p>
          <h3>Font Awesome (lokales Hosting)</h3> <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
          Font Awesome. Font Awesome ist lokal installiert. Eine Verbindung zu Servern von Fonticons, Inc. findet dabei
          nicht statt.</p> <p>Weitere Informationen zu Font Awesome finden Sie in der Datenschutzerklärung für Font
          Awesome unter: <a href='https://fontawesome.com/privacy' target='_blank'
                            rel='noopener noreferrer'>https://fontawesome.com/privacy</a>.</p>
          <h3>Google Maps</h3> <p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland
          Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p> <p>Zur Nutzung der Funktionen von
          Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden in der Regel an einen
          Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss
          auf diese Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum Zwecke der einheitlichen
          Darstellung der Schriftarten Google Web Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die
          benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p> <p>Die Nutzung
          von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer
          leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse
          im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
          die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
          widerrufbar.</p> <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
          gestützt. Details finden Sie hier: <a href='https://privacy.google.com/businesses/gdprcontrollerterms/'
                                                target='_blank'
                                                rel='noopener noreferrer'>https://privacy.google.com/businesses/gdprcontrollerterms/</a> und <a
            href='https://privacy.google.com/businesses/gdprcontrollerterms/sccs/' target='_blank'
            rel='noopener noreferrer'>https://privacy.google.com/businesses/gdprcontrollerterms/sccs/</a>.</p> <p>Mehr
          Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a
            href='https://policies.google.com/privacy?hl=de' target='_blank'
            rel='noopener noreferrer'>https://policies.google.com/privacy?hl=de</a>.</p>
          <h3>Google reCAPTCHA</h3> <p>Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website.
          Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p> <p>Mit
          reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem Kontaktformular) durch
          einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des
          Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher
          die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse,
          Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der
          Analyse erfassten Daten werden an Google weitergeleitet.</p> <p>Die reCAPTCHA-Analysen laufen vollständig im
          Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse stattfindet.</p> <p>Die
          Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
          hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und
          vor SPAM zu schützen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
          <p>Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den Google
            Nutzungsbedingungen unter folgenden Links: <a href='https://policies.google.com/privacy?hl=de'
                                                          target='_blank'
                                                          rel='noopener noreferrer'>https://policies.google.com/privacy?hl=de</a> und <a
              href='https://policies.google.com/terms?hl=de' target='_blank'
              rel='noopener noreferrer'>https://policies.google.com/terms?hl=de</a>.</p>

        </DisclaimerWrapper>
      </PageSection>
    </>
  );
};