import React, { FC } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Jobs } from './pages/Jobs';
import { Job } from './pages/Job';
import { PageHeader } from './components/PageHeader';
import { PageFooter } from './components/PageFooter';
import { Navigation } from './components/Navigation';
import { Imprint } from './pages/imprint';
import { Disclaimer } from './pages/disclaimer';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { NewsletterOptIn } from './pages/NewsletterOptIn';
import { Profile } from './pages/Profile';
import { Company } from './pages/Company';
import { NewsletterOptOut } from './pages/NewsletterOptOut';

export const App: FC = () => {
  return <>
    <BrowserRouter>
      <Navigation />
      <PageHeader />
      <main>
        <Switch>
          <Route exact path={'/'} component={Home} />
          <Route path={'/newsletter/opt-in'} component={NewsletterOptIn} />
          <Route path={'/newsletter/opt-out'} component={NewsletterOptOut} />
          {
            //  <Route path={'/jobs'} exact component={Jobs} />
          }
          {
            //   <Route path={'/jobs/:slug'} component={Job} />
          }
          {
            //  <Route path={'/register'} component={Register} />
          }

          {
            // <Route path={'/login'} component={Login} />
          }

          {
            // <Route path={'/profile'} component={Profile} />
          }

          {
            // <Route path={'/company'} component={Company} />
          }

          <Route path={'/imprint'} component={Imprint} />
          <Route path={'/disclaimer'} component={Disclaimer} />
        </Switch>
        <PageFooter />
      </main>
    </BrowserRouter>
  </>;
};

